import './resume.css';
import React from 'react'
import CV from '../../assets/Resume2025PDF.pdf'
import CL from '../../assets/prb_2024_cover_letter.pdf'
import { Link } from 'react-router-dom';
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { BsFillEnvelopeFill } from 'react-icons/bs'
import { FiGlobe } from 'react-icons/fi'

export const Resume = () => {
    return (
        <>
            <section className="resume_section container" id="resume">
                <h1 className="resume_name">Paul Black</h1>
                <div className="resume_contact_container">
                    <Link to="mailto:ramoneblack@icloud.com"><BsFillEnvelopeFill class="resume_icons" /><span className="resume_emai">ramoneblack@icloud.com</span></Link>
                    <Link to="/#hero"><FiGlobe class="resume_icons" /><span className="website">paulrblack.com</span></Link>
                    <Link to="https://github.com/P-R-Black" target="_blank" rel="noreferrer">
                        <FaGithub class="resume_icons" /><span className="resume_github">Github</span>
                    </Link>
                    <Link to="https://www.linkedin.com/in/paul-black-669533145/" target="_blank" rel="noreferrer">
                        <BsLinkedin class="resume_icons" /><span className="linkedIn">Linkedin</span>
                    </Link>
                </div>
                <div className="skills_section">
                    <h3 className="resume_skills"><strong>Objective</strong><span></span></h3>
                    <div className="objective_container">
                        <p>
                            Aspiring software engineer with a strong foundation in web development technologies, including
                            React, Python, and PostgreSQL. Passionate about building innovative, user-friendly software
                            solutions while continuously learning and contributing to impactful projects.
                        </p>

                    </div>
                </div>
                <div className="skills_section">
                    <h3 className="resume_skills"><strong>Skills</strong><span></span></h3>
                    <div className="resume_list_style">
                        <div className="circle"></div>
                        <ul className="skill_list">
                            <li>Front-end:</li>
                            <li className="resume_skill">Bootstrap,</li>
                            <li className="resume_skill">CSS,</li>
                            <li className="resume_skill">HTML,</li>
                            <li className="resume_skill">JavaScript,</li>
                            <li className="resume_skill">React</li>
                        </ul>
                    </div>
                    <div className="resume_list_style">
                        <div className="circle"></div>
                        <ul className="skill_list">
                            <li>Back-end:</li>
                            <li className="resume_skill">Django,</li>
                            <li className="resume_skill">Django Rest Framework,</li>
                            <li className="resume_skill">PostgreSQL,</li>
                            <li className="resume_skill">Python,</li>
                            <li className="resume_skill">Redis,</li>
                            <li className="resume_skill">SQL</li>
                        </ul>
                    </div>
                </div>
                <div className="resume_project_section">
                    <h3 className="resume_projects"><strong>Projects</strong><span></span></h3>
                    <div className="project_list_style">
                        <div className="circle "></div>
                        <Link to="https://melete.paulrblack.com">
                            <h4>Keep's Guide:<span>A cocktail recipe app created with React and Django Rest Framework.</span> <span><Link to="https://github.com/P-R-Black/drinks-frontend" target='_blank'><FaGithub className="projectGit" /></Link></span></h4>
                        </Link>
                    </div>
                    <div className="project_list_style">
                        <div className="circle"></div>
                        <Link to="https://sevillereport.com/">
                            <h4>The Seville Report:<span>An investment newsletter site created with Django and designed for digital downloads.</span>  <span ><Link to="https://github.com/P-R-Black/SR_website" target='_blank'><FaGithub className="projectGit" /></Link></span></h4>

                        </Link>
                    </div>
                    <div className="project_list_style">
                        <div className="circle "></div>
                        <Link to="https://melete.paulrblack.com">
                            <h4>Melete:<span> A meditation app developed with Django and PostgreSQL.</span>  <span><Link to="https://github.com/P-R-Black/DjangoMedApp " ><FaGithub className="projectGit" /></Link></span></h4>

                        </Link>
                    </div>
                </div>
                <div className="resume_experience_section">
                    <h3 className="resume_experience"><strong>Experience</strong><span></span></h3>
                    <div className="top_line_experience">
                        <h4 className="experience_title"><strong>Web Developer</strong></h4>
                        <h4 className="experience_place">Freelance</h4>
                        <h4 className="experience_location">Kissimmee, FL</h4>
                    </div>
                    <div className="work_dates">
                        <h4>JAN. 2014 - PRESENT</h4>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Design, develop, and maintain custom websites and web applications tailored to client requirements, ensuring responsive design and optimal user experience.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Collaborate with clients to gather project specifications, provide technical guidance, and deliver solutions within deadlines and budget constraints.</li>
                        </ul>
                    </div>
                    <div className="top_line_experience">
                        <h4 className="experience_title"><strong>Liability and Medical Bill Examiner</strong></h4>
                        <h4 className="experience_place">Freelance</h4>
                        <h4 className="experience_location">Kissimmee, FL</h4>
                    </div>
                    <div className="work_dates">
                        <h4>NOV. 2014 - PRESENT</h4>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Provided attorneys with insight into liability decisions from accident investigations, aiding case prioritization.</li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Successfully contested denied medical bills for physicians and medical facilities, resulting in significant reimbursements.</li>
                        </ul>
                    </div>
                    <div className="top_line_experience trainer">
                        <h4 className="experience_title"><strong> Liability and Medical Examiner, Trainer</strong></h4>
                        <h4 className="experience_place">GEICO</h4>
                        <h4 className="experience_location">Woodbury, NY</h4>
                    </div>
                    <div className="work_dates">
                        <h4>JAN. 2003 - AUG. 2014</h4>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Investigated automobile accidents by gathering evidence from witnesses and law enforcement.
                            </li>
                        </ul>
                    </div>
                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Trained new hires on claim investigations, liability negotiation, and conflict resolution, reducing onboarding time by 50%.</li>
                        </ul>
                    </div>

                    <div className="experience_body_section">
                        <div className="circle"></div>
                        <ul className="experience_body">
                            <li>Collaborated with the arbitration team to resolve unpaid claims, saving the company hundreds of thousands in fees and interest.</li>
                        </ul>
                    </div>
                </div>
                <div className="resume_education_section">
                    <h3 className="education_experience"><strong>Education</strong><span></span></h3>
                    <div className="education_top_line">
                        <h4 className="experience_title"><strong>Bachelor of Science in Finance</strong></h4>
                        <h4 className="education_place">Long Island University, Brooklyn, NY | JAN. 2001 - MAY 2007</h4>
                    </div>

                    <div className="education_top_line">
                        <h4 className="experience_title"><strong>Bachelor of Arts in Business Management</strong></h4>
                        <h4 className="education_place">Long Island University, Brooklyn, NY | JAN. 2001 - MAY 2007</h4>
                    </div>
                </div>
            </section>
            <div className="container download_resume">
                <a href={CV} download className='button_light resume'>{"Download Resume"}</a>
                <a href={CL} download className='button_dark resume'>{"Download Cover Letter"}</a>
            </div>
        </>
    )
}
